import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {Merchant, NewMerchant} from "../types/merchants"
import {ecommerceServiceURL} from "../config"

export type PatchMerchant = (merchant: NewMerchant) => Promise<AxiosResponse<Merchant> | undefined>

export const patchMerchant: PatchMerchant = async (merchant: NewMerchant) => {
  try {
    const path = `/v3/merchants/${merchant.shop}`
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: ecommerceServiceURL,
      headers: headers,
    }

    const body = {
      plan: merchant.plan
    }

    return await axiosInstance.patch<Merchant>(path, body, opts)
  } catch (error) {
    console.error('Error updating merchant:', error)
    throw error
  }
}