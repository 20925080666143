import React, {useState} from 'react'
import './CreateUserModal.css'
import FormControl from '@mui/material/FormControl'
import {Button,   TextField} from "@mui/material"
import { Undefinable} from "../../types/utils"
import { PostUserInput } from 'src/types/users'
import { postUser } from 'src/apis/postuser'

interface UserModalProps {
  isOpen: boolean
  onClose: () => void
  refreshTable: () => void
}

const CreateUserModal: React.FC<UserModalProps> = ({isOpen, onClose, refreshTable}) => {
  const [validationError, setValidationError] = useState<string>('')

  // Define the initial state of the post user input.
  const [postUserInput, setPostUserInput] = useState<PostUserInput>(() => {
      let obj = {
        email: '',
        firstName: '',
        lastName: '',
      } 
      return obj as PostUserInput
  })

  if (!isOpen) return null

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let firstName = event.target.value

    setPostUserInput(prev => ({ ...prev, firstName: firstName }))
  }

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let lastName = event.target.value

    setPostUserInput(prev => ({ ...prev, lastName: lastName }))
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let email = event.target.value

    setPostUserInput(prev => ({ ...prev, email: email }))
  }

  const showAlert = (message: string) => alert(message)

  const handleApiResponse = (response: Undefinable<{ status: number }>, successCode: number) => {
    if (response && response.status === successCode) {
      refreshTable()
      onClose()
    } else {
      showAlert('There was an error. Please try again.')
    }
  }

  const validEmail = (email: string | undefined): boolean =>  {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (! email || !emailRegex.test(email)) {
      setValidationError('Please enter a valid email address.');
      return false
    } else {
      setValidationError('')
      return true
    }
  }

  const handleActionClick = async () => {
    // ensure we have a properly formatted email before we call the apis.
    if (!validEmail(postUserInput.email)) {
      return
    }

    // attempt to call the API.
    try {
      let response
      response = await postUser(postUserInput)
      handleApiResponse(response, 201)
    } catch (e) {
      showAlert(`There was an error creating the user. Please try again.`)
    }
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <h2 className="modal-header">{'Create User'}</h2>
        <FormControl className="formControlsContainer">
          <TextField
            onChange={handleFirstNameChange}
            label='First Name'
            variant="outlined"
            value={postUserInput.firstName}
          />
        </FormControl>
        <FormControl className="formControlsContainer">
          <TextField
            onChange={handleLastNameChange}
            label='Last Name'
            variant="outlined"
            value={postUserInput.lastName}
          />
        </FormControl>
        <FormControl className="formControlsContainer">
          <TextField
            onChange={handleEmailChange}
            label='Email'
            variant="outlined"
            value={postUserInput.email}
          />
        </FormControl>
        {validationError && <span style={{color: 'red'}}>{validationError}</span>}
        {/* Create and Cancel Buttons */}
        <div className="modal-actions">
          <Button className="create-user-button" variant="contained" onClick={handleActionClick}>
            {'Create User'}
          </Button>
          <Button className="cancel-user-button" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateUserModal
