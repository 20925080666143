import { MerchantPlatform } from "./merchants"

export enum UserType {
  Merchant = "MERCHANT",
  Influencer = "INFLUENCER",
  None = "NONE",
}

export type User = {
  id: string
  type: UserType
  email?: string
  firstName?: string
  lastName?: string
  orgsAndRoles: UserOrgRole[]
}

export enum Role {
  Admin = "Admin",
}

export type UserOrgRole = {
  orgId: string
  userId: string
  roles: Role[]
}

export type PostUserInput = {
  email: string
  firstName?: string
  lastName?: string
}

export type NewUser = {
  email?: string
  firstName?: string
  lastName?: string
  shop: string
  platform: MerchantPlatform
}

// GetUsersParams is a type that represents the query params to the getUsers API
export type GetUsersParams = {
  UserIds?: string
  orgId?: string
  handle?: string
  limit: number
  offset: number
}

export type PatchUserPayload = {
  firstName: string
  lastName: string
  resetPassword: boolean
}