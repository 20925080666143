import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {iamServiceURL} from "../config"
import { UserOrgRole } from 'src/types/users'

export type PostUserOrgRole = (body: UserOrgRole) => Promise<AxiosResponse<UserOrgRole> | undefined>

export const postUserOrgRole: PostUserOrgRole = async (body: UserOrgRole) => {
  try {
    const path = '/v3/users/org-role'
    const headers = {
      'Content-Type': 'application/json',
      'Merchant-Org-Id': 'default' //TODO: will this allow me to create any org for any user?
    }

    const opts = {
      baseURL: iamServiceURL,
      headers: headers,
    }

    return await axiosInstance.post<UserOrgRole>(path, body, opts)
  } catch (error) {
    console.error('Error creating org role:', error)
    throw error
  }
}