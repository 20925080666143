import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {iamServiceURL} from "../config"
import { PatchUserPayload } from 'src/types/users'

export type PatchUser = (userId: string, body: PatchUserPayload) => Promise<AxiosResponse<object> | undefined>

export const patchUser: PatchUser = async (userId: string, body: PatchUserPayload) => {
  try {
    const path = `/v3/users/${userId}`
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: iamServiceURL,
      headers: headers,
    }

    return await axiosInstance.patch<object>(path, body, opts)
  } catch (error) {
    console.error('Error patching user:', error)
    throw error
  }
}