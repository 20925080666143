import axios, { InternalAxiosRequestConfig } from 'axios'
import { fetchAuthSession } from 'aws-amplify/auth'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    try {
      const token = await getAuthToken()
      if (token) {
        // use bearer tokens for v3 apis.
        if (usingV3API(config)) {
          config.headers.set('Authorization', `Bearer ${token}`)
        } else {
          config.headers.set('Authorization', token)
        }
      }
    } catch (error) {
      console.error('Error getting auth token', error)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


// Helper function to get the current user's token
async function getAuthToken(): Promise<string | undefined> {
  try {
    const session = await fetchAuthSession()
    return session.tokens?.accessToken?.toString()
  } catch (error) {
    console.error('Error getting current session', error)
    return undefined
  }
}

// helper function to determine what version of the API we're using.
const usingV3API = (config: InternalAxiosRequestConfig) => {
  return typeof config.url === 'string' && config.url.startsWith('/v3');
};

export {axiosInstance}