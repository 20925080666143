export enum OrgType  {
  Merchant = "MERCHANT",
  Influencer = "INFLUENCER",
  None = "NONE",
}

export type GetOrgsInput =  {
  limit: number
  offset: number
  ids: string[]
  type: OrgType
} 

export type Org = {
  id: string
  type: OrgType
  influencerHandle?: string
}

export type GetOrgsOutput = {
  orgs: Org[]
}
