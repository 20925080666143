import React, {useEffect, useState} from 'react'
import {getUsers} from 'src/apis/getusers'
import './Users.css'
import {
  User,
  GetUsersParams
} from "../../types/users"
import DeleteUserModal from 'src/components/DeleteUserModal/DeleteUserModal'
// import {formatMillisToDate} from "../../utils/time"
import {Image} from "@aws-amplify/ui-react"
import {deleteIcon, editIcon} from "../../utils/logos"
import {Button , FormControl, IconButton, InputLabel, Select,SelectChangeEvent, MenuItem} from "@mui/material"
import {Nullable} from "../../types/utils"
import CreateUserModal from 'src/components/CreateUserModal/CreateUserModal'
import { useNavigate } from 'react-router-dom'

const pageSizes = [10, 25, 50, 100, 200]

const Users = () => {
  // dummy state object to trigger a refresh based on operations in the modal. 
  // not sure if there's a better way, but this works.
  const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])
  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(true)
  const [usersParams, setUsersParams] = useState<GetUsersParams>({
    limit: 25,
    offset: 0,
  })
  const navigate = useNavigate()

  useEffect(() => {
    // Fetch user data when the component mounts
    const fetchUsers = async () => {
      try {
        const response = await getUsers(usersParams)
        if (response && response.data) {
          setUsers(response.data)
          setHasMoreUsers(response.data.length === usersParams.limit)
        } else {
          setHasMoreUsers(false)
        }
      } catch (error) {
 
       // Handle errors
        console.error('Error fetching users:', error)
      }
    }

    fetchUsers()
  }, [usersParams,triggerRefresh]) // refetch our user state when we trigger a refresh.

  const handlePrevious = () => {
    setUsersParams((prevParams) => ({
      ...prevParams,
      offset: (prevParams.offset - prevParams.limit)
    }))
  }

  const handleNext = () => {
    setUsersParams((prevParams) => ({
      ...prevParams,
      offset: (prevParams.offset + prevParams.limit)
    }))
  }

  const handlePerPageChange = (event: SelectChangeEvent<number>) => {
    const perPage = Number(event.target.value)

    setUsersParams((prevParams) => ({
      offset: 0,
      limit: perPage,
    }))
  }

  enum ModalType {
    NONE = '',
    USER_DELETE = 'user_delete',
    USER = 'user',
  }

  const [openModal, setOpenModal] = useState<ModalType>(ModalType.NONE)
  const [selectedUser, setSelectedUser] = useState<Nullable<User>>(null)

  const handleDeleteUserClick = (user: User) => {
    setSelectedUser(user)
    setOpenModal(ModalType.USER_DELETE)
  }

  const handleCreateUserClick = () => {
    setSelectedUser(null)
    setOpenModal(ModalType.USER)
  }

  // navigate to a new page if we want to edit a user.
  const handleUpdateUserClick = (user: User) => {
    navigate(`/users/${user.id}`)
  }

  const handleCloseModal = () => {
    setOpenModal(ModalType.NONE)
  }

  const handleRefreshTable = () => {
    setTriggerRefresh(!triggerRefresh)
  }

  return (
    <div className="table-container">
      <h1>Users</h1>
      <div className="controls">
        <div className="pagination-controls">
          <FormControl variant="outlined" className="form-control">
            <InputLabel htmlFor="status-select" className="form-label">
              Select Page Size:
            </InputLabel>
            <Select
              value={usersParams.limit}
              onChange={handlePerPageChange}
              displayEmpty
              inputProps={{'aria-label': 'Without label', id: 'status-select'}}
            >
              {pageSizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button onClick={handleCreateUserClick} className="create-button" variant='contained'>Create
          User</Button>
      </div>

      {/* Delete User Modal*/}
      {openModal === ModalType.USER_DELETE && (
        <DeleteUserModal
          isOpen={true}
          onClose={handleCloseModal}
          user={selectedUser}
          refreshTable={handleRefreshTable}
        />
      )}

      {/* Create / Edit User Modal*/}
      {openModal === ModalType.USER && (
        <CreateUserModal
          isOpen={true}
          onClose={handleCloseModal}
          refreshTable={handleRefreshTable}
        />
      )}

      {/* Modal Overlay */}
      {openModal && (
        <div className="overlay" onClick={handleCloseModal}></div>
      )}


      <table>
        <thead>
        <tr>
          <th>Key</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Shops</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {users.map(user => (
          <tr key={user.id}>
            <td>
              <span className="cell-text">{user.id}</span>
            </td>
            <td><span className="cell-text">{user.firstName}</span></td>
            <td><span className="cell-text">{user.lastName}</span></td>
            <td><span className="cell-text">{user.email}</span></td>
            <td><span className="cell-text">{user.orgsAndRoles ? user.orgsAndRoles.map((org) => org.orgId).join(', ') : 'N/A'}</span></td>
            <td>
            <IconButton aria-label="edit" onClick={() => {
                handleUpdateUserClick(user)
              }} className='icon-button'>
                <Image
                  alt={'Edit Icon'}
                  src={editIcon}
                />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => {
                handleDeleteUserClick(user)
              }} className='icon-button'>
                <Image
                  alt={'Delete Icon'}
                  src={deleteIcon}
                />
              </IconButton>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <div className="pagination-controls">
        <button onClick={handlePrevious} disabled={usersParams.offset === 0}>
          Previous
        </button>
        <button onClick={handleNext}
                disabled={!hasMoreUsers}>
          Next
        </button>
      </div>
    </div>
  )
}

export default Users
