import { AxiosResponse } from "axios";
import { GetOrgsInput, GetOrgsOutput } from "src/types/orgs";
import { axiosInstance } from "src/utils/axios";
import {iamServiceURL} from "../config"

export type GetOrgs = (input: GetOrgsInput) => Promise<AxiosResponse<GetOrgsOutput> | undefined>

export const getOrgs: GetOrgs = async(input: GetOrgsInput) => {
  try {
    var path = `/v3/org?limit=${input.limit || 25}&offset=${input.offset || 0}`
    if (input.ids && input.ids.length > 0) {
      path += `&ids=${input.ids.join(',')}`
    }
    if (input.type ) {
      path += `&orgType=${input.type}`
    }

    const headers = {
      'Content-Type': 'application/json'
    }

    const opts = {
      baseURL: iamServiceURL,
      headers: headers,
    }

    return await axiosInstance.get<GetOrgsOutput>(path, opts)
  } catch (error) {
    console.error('error getting orgs')
    throw error
  }
}