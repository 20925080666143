import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {GetMerchantsParams, Merchant} from "../types/merchants"
import {ecommerceServiceURL} from "../config"

export type GetMerchants = (params: GetMerchantsParams) => Promise<AxiosResponse<Merchant[]> | undefined>

export const getMerchants: GetMerchants = async (params: GetMerchantsParams) => {
  try {
    const path = '/v3/merchants'
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: ecommerceServiceURL,
      headers: headers,
      params: params
    }

    return await axiosInstance.get<Merchant[]>(path, opts)
  } catch (error) {
    console.error('Error fetching merchants:', error)
    throw error
  }
}