import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {GetUsersParams, User} from "../types/users"
import { iamServiceURL} from "../config"

export type GetUsers = (params: GetUsersParams) => Promise<AxiosResponse<User[]> | undefined>

export const getUsers: GetUsers = async (params: GetUsersParams) => {
  try {
    const path = '/v3/users'
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: iamServiceURL,
      headers: headers,
      params: params
    }

    return await axiosInstance.get<User[]>(path, opts)
  } catch (error) {
    console.error('Error fetching users:', error)
    throw error
  }
}