import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import { iamServiceURL} from "../config"

export type DeleteUser = (userId: string) => Promise<AxiosResponse<object> | undefined>

export const deleteUser: DeleteUser = async (userId: string) => {
  try {
    const path = `/v3/users/${userId}`
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: iamServiceURL,
      headers: headers,
    }

    return await axiosInstance.delete(path, opts)
  } catch (error) {
    console.error('Error deleting user:', error)
    throw error
  }
}