import React, { useEffect, useState } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import '@aws-amplify/ui-react/styles.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import Home from './pages/Home/Home'
import Merchants from './pages/Merchants/Merchants'
import Users from "./pages/Users/Users"
import EditUser from './pages/Users/EditUser/EditUser'

function App() {
  const [hasInternalAccess, setHasInternalAccess] = useState<boolean>(false)

  useEffect(() => {
    checkUserGroup()
  }, [])

  const checkUserGroup = async () => {
    try {
      const session = await fetchAuthSession()

      const groups = session.tokens?.accessToken?.payload['cognito:groups']
      if (groups && Array.isArray(groups) && groups.includes('internal_access')) {
        setHasInternalAccess(true)
      } else {
        setHasInternalAccess(false)
        await signOut()
      }
    } catch (error) {
      console.error('Error checking user group:', error)
    }
  }

  if (!hasInternalAccess) {
    return <div className="loader"></div>
  }

  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/merchants" element={<Merchants />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:userId" element={<EditUser />} />
        </Routes>
      </div>
    </Router>
  )
}

export default withAuthenticator(App)
