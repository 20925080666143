import React from 'react'
import { signOut } from '@aws-amplify/auth'
import { Image } from '@aws-amplify/ui-react'
import { Link } from 'react-router-dom' // Import Link
import './Navbar.css' // Make sure the path is correct based on your file structure

const Navbar = () => {
  const handleLogout = async () => {
    try {
      await signOut()
      window.location.reload()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  return (
    <nav className="navbar">
      <div style={{ display: 'flex', alignItems: 'center' }}> {/* Grouping logo and Plans button */}
        <Link to="/" className="navbar-image-link">
          <Image
            alt="SimplicityDX Logo"
            src="/static/images/simplicitydx_logo_full_dark.svg"
            className="navbar-image"
          />
        </Link>
        <Link to="/merchants" className="navbar-button">
          Merchants
        </Link>
        <Link to="/users" className="navbar-button">
          Users
        </Link>
      </div>
      <button onClick={handleLogout} className="navbar-button">
        Logout
      </button>
    </nav>
  )
}

export default Navbar
